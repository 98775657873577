import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: block;
  margin: 0 auto;
  position: relative;
  width: 100%;
  height: 100%;

  a {
    text-decoration: none;
  }

  ${MEDIA.PHONE`
  display: block;`}

  .gatsby-image-wrapper::before {
    content: '';
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.1) 100%
    );

    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 2;
  }

  .reveal-img {
    height: 100%;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 500px;
  }

  & > *:first-child {
    position: absolute;
    height: 100%;
    z-index: 3;
    width: 100%;
    top: 0;
    left: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 100%;
  }
`;
