import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './column.css';

const Column = ({ children, bg, width = '100%', align, display = 'block' }) => (
  <Container width={width} align={align} bg={bg} display={display}>
    {children}
  </Container>
);

Column.propTypes = {
  children: PropTypes.node,
  bg: PropTypes.string,
  width: PropTypes.string,
  align: PropTypes.string,
  display: PropTypes.string,
};

export default Column;
