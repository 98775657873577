import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './centeredImageText.css';

const CenteredImageText = ({ children, bg }) => (
  <Container bg={bg}>{children}</Container>
);

CenteredImageText.propTypes = {
  children: PropTypes.node.isRequired,
  bg: PropTypes.string,
};

export default CenteredImageText;
