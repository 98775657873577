import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Title from 'components/title';
import { graphql, Link } from 'gatsby';
import Button from 'components/button';
import Image from 'components/image';
import Section from '../containers/section';
import Column from '../containers/column';
import { theme } from 'constants/theme';
import OverlapSection from '../containers/overlap-section';
import Carousel from 'components/carousel';
import { SwiggleIcon, BathIcon, FireIcon } from 'components/icon';
import IconAndText from 'components/iconAndText';
import CenteredImageText from 'containers/centeredImageText';

const Index = ({ data }) => (
  <Layout headerTheme="light">
    <Section bg={theme.colors.base} height="100vh">
      <OverlapSection>
        <div>
          <Title as="h1" size="large" color={theme.colors.bg}>
            {data.homeJson.content.childMarkdownRemark.rawMarkdownBody}
          </Title>

          <Button
            url={data.homeJson.heroButtonLink}
            text={data.homeJson.heroButtonText}
            light
          />
        </div>
        <Image
          src={data.homeJson.heroImage.image}
          alt={data.homeJson.heroImage.title}
        />
      </OverlapSection>
    </Section>
    <Section type="block">
      <SwiggleIcon />
      <Title as="h2" color={theme.colors.base} align="center">
        NEST AT BLUE BAY is a newly handcrafted and designed space perfect for a
        relaxing, restful retreat or romantic getaway.
      </Title>
      <Image
        src={data.homeJson.image1.image}
        alt={data.homeJson.image1.title}
      />
    </Section>
    <Section reverseOnMobile>
      <Column>
        <Image
          src={data.homeJson.image2.image}
          alt={data.homeJson.image2.title}
        />
      </Column>
      <Column>
        <Title as="h2" size="large" color={theme.colors.base}>
          A Place for you to Unwind.
        </Title>
        <p>
          Nest at Blue Bay is a luxury couples accommodation situated in the
          middle of the two spectacular bays, Blue Bay and Toowoon Bay. Both
          beaches are just a 5 minute stroll away with the trendy local cafes
          and boutique restaurants in the village less than 200 mts. Leave the
          car parked undercover and walk everywhere or hop on our complimentary
          bikes and discover the bike track along the lake to The Entrance and
          surrounds.
        </p>
        <p>
          Focusing on spoiling our guests, we welcome you with fresh seasonal
          fruit, milk and all the basic pantry items you may need. Chill out on
          your private deck and read up on all the ‘must do and see’ things
          around the area.
        </p>
        <IconAndText>
          <BathIcon />
          <h6>Stone Bath</h6>
        </IconAndText>
        <IconAndText>
          <FireIcon />
          <h6>Fireplace</h6>
        </IconAndText>
        <IconAndText>
          <SwiggleIcon />
          <h6>5 min walk to beach</h6>
        </IconAndText>
        <br />
        <Button
          url="/things-to-do"
          text="Explore the area"
          margin="2rem 0"
          dark
        />
      </Column>
    </Section>
    <Section
      width="60%"
      padding="50px 5vw 0"
      type="grid"
      bg={theme.colors.bg2}
      columns="2fr 1fr"
    >
      <Column>
        <Title as="h3" size="large">
          &ldquo;{data.homeJson.testimonial.title}&rdquo;
        </Title>
        <p>&ldquo;{data.homeJson.testimonial.body}&rdquo;</p>
        <p>- {data.homeJson.testimonial.name}</p>
      </Column>
      <Column></Column>
    </Section>
    <Section padding="50px 0" type="block" bg={theme.colors.bg2}>
      <Carousel items={data.homeJson.carousel} />
    </Section>
    <Section bg={theme.colors.base} height="100vh">
      <CenteredImageText>
        <Link to="/bookings">
          <Title as="h3" size="giant" color={theme.colors.bg} align="center">
            Make a reservation
          </Title>
        </Link>

        <Image
          src={data.homeJson.image3.image}
          alt={data.homeJson.image3.title}
        />
      </CenteredImageText>
    </Section>
  </Layout>
);

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      heroButtonText
      heroButtonLink
      heroImage {
        image {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
      }
      testimonial {
        title
        body
        name
      }
      image1 {
        image {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
      }
      image2 {
        image {
          childImageSharp {
            fluid(maxWidth: 900, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
      }
      image3 {
        image {
          childImageSharp {
            fluid(maxWidth: 900, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        title
      }
      carousel {
        title
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
