import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: grid;
  grid-template: repeat(3, [row] 1fr) / repeat(12, [col] 1fr);
  grid-gap: 20px;
  margin: 0 auto;
  height: 75vh;
  width: 100%;

  ${MEDIA.PHONE`
  display: flex;
  height: auto;
  flex-direction: column;
  `}

  .gatsby-image-wrapper {
    height: 100%;
  }

  .gatsby-image-wrapper::before {
    content: '';
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 100%
    );

    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 2;
  }

  & > *:first-child {
    grid-column: col 1 / span 7;
    grid-row: row 1 / span 3;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
  }

  & > *:nth-child(2) {
    grid-column: col 4 / span 9;
    grid-row: row 1 / span 3;
    z-index: 1;
  }
`;
