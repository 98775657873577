import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const Item = ({ title, image }) => (
  <Img
    fluid={image ? image.childImageSharp.fluid : {}}
    alt={title}
    className="keen-slider__slide"
    style={{ position: 'absolute' }}
  />
);

Item.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object.isRequired,
};

export default Item;
