import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container } from './fadeUp.css';

import gsap, { Power2 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const FadeUp = ({ children }) => {
  const fadeupRef = useRef(null);

  useEffect(() => {
    let element = fadeupRef.current;
    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: fadeupRef.current,
        start: 'top bottom',
      },
    });
    tl.set(element, { autoAlpha: 1, delay: 1 });
    tl.from(element, 1.3, {
      y: 30,
      opacity: 0,
      ease: Power2.out,
    });
  }, []);

  return (
    <Container className="fade-up" ref={fadeupRef}>
      {children}
    </Container>
  );
};

FadeUp.propTypes = {
  children: PropTypes.any,
};

export default FadeUp;
