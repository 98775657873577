import styled from 'styled-components';

export const Container = styled.span`
  display: flex;
  text-align: ${props => props.align};
  color: ${props => props.base};
  max-width: 800px;
  text-transform: uppercase;
  font-weight: 200;
  font-family: Silk, serif;
  justify-content: flex-start;
  align-items: center;

  h6 {
    font-weight: 900;
    text-transform: lowercase;
  }

  svg {
    margin-right: 2rem;
    width: 2.5rem;
  }
`;
