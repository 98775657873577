import React from 'react';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import PropTypes from 'prop-types';
import { Container } from './carousel.css';
import Item from 'components/carousel/item';

const Carousel = ({ items }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  function ArrowLeft(props) {
    const disabled = props.disabled ? ' arrow--disabled' : '';
    return (
      <svg
        onClick={props.onClick}
        className={'arrow arrow--left' + disabled}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      </svg>
    );
  }

  function ArrowRight(props) {
    const disabled = props.disabled ? ' arrow--disabled' : '';
    return (
      <svg
        onClick={props.onClick}
        className={'arrow arrow--right' + disabled}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      </svg>
    );
  }

  const [sliderRef, slider] = useKeenSlider({
    slidesPerView: 2.5,
    breakpoints: {
      '(max-width: 768px)': {
        slidesPerView: 1.5,
      },
    },
    mode: 'free-snap',
    spacing: 30,
    centered: false,
    loop: false,
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  return (
    <>
      <Container className="navigation-wrapper">
        <div ref={sliderRef} className="keen-slider">
          {items.map((item, i) => (
            <Item {...item} key={i} />
          ))}
        </div>
        {slider && (
          <>
            <ArrowLeft
              onClick={e => e.stopPropagation() || slider.prev()}
              disabled={currentSlide === 0}
            />

            <ArrowRight
              onClick={e => e.stopPropagation() || slider.next()}
              disabled={currentSlide === slider.details().size - 1}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default Carousel;

Carousel.propTypes = {
  onClick: PropTypes.any,
  disabled: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

/* const Carousel = ({ items }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [sliderRef, slider] = useKeenSlider({
    breakpoints: {
      '(max-width: 1000px)': {
        slidesPerView: 1.5,
      },
    slidesPerView: 2.5,
    mode: 'free-snap',
    spacing: 30,
    centered: true,
    loop: false,
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  return (
    <div className="navigation-wrapper">
      <Container ref={sliderRef} className="keen-slider">
        {items.map((item, i) => (
          <Item {...item} key={i} />
        ))}
      </Container>
      {slider && (
        <div className="dots">
          {[...Array(slider.details().size).keys()].map(idx => {
            return (
              <button
                key={idx}
                onClick={() => {
                  slider.moveToSlideRelative(idx);
                }}
                className={'dot' + (currentSlide === idx ? ' active' : '')}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Carousel;

Carousel.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};
 */
