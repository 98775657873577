import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './button.css';
import { Link } from 'gatsby';
import Icon from '../../images/nest-arrow.svg';
import FadeUp from '../animations/fadeUp';

const Button = ({ url, text, dark, light, margin }) => {
  return (
    <FadeUp>
      <Container dark={dark} light={light} margin={margin}>
        <Link to={url}>
          {text}
          <Icon />
        </Link>
      </Container>
    </FadeUp>
  );
};

Button.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  dark: PropTypes.any,
  light: PropTypes.any,
  margin: PropTypes.string,
};

export default Button;
