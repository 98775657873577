import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './iconAndText.css';

const IconAndText = ({ children, as = 'span', color, align = 'left' }) => {
  return (
    <Container as={as} color={color} align={align}>
      {children}
    </Container>
  );
};

IconAndText.propTypes = {
  children: PropTypes.any,
  as: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
};

export default IconAndText;
