import styled from 'styled-components';
import { theme } from 'constants/theme';

export const Container = styled.div`
  margin: ${props => (props.margin ? props.margin : '0 0 2rem')};
  width: max-content;

  &:hover {
    a::after {
      transform: scaleX(1);
      transform-origin: right;
    }
  }

  a {
    text-transform: uppercase;
    color: ${props =>
      props.light ? `${theme.colors.bg}` : `${theme.colors.base}`};
    text-decoration: none;
    font-size: 1.4rem;
    letter-spacing: 2px;
    font-weight: 800;
    display: inline-flex;
    align-items: center;
    position: relative;

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      background: ${props =>
        props.light ? `${theme.colors.bg}` : `${theme.colors.base}`};
      bottom: 0;
      left: 0;
      display: block;
      transform: scaleX(0);
      transition: 0.2s ease-out;
      transform-origin: left;
    }
  }
  svg {
    width: 1rem;
    margin-left: 1rem;

    path {
      fill: ${props =>
        props.light ? `${theme.colors.bg}` : `${theme.colors.base}`};
    }
  }
`;
