import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  line-height: 1;
  display: ${props => props.display};
  width: 100%;
  margin: 0;
  margin-left: ${({ align }) => () => {
    switch (align) {
      case 'left':
        return '0';
      case 'right':
        return 'auto';
      default:
        return '0';
    }
  }};
  max-width: ${props => props.width};

  ${MEDIA.TABLET`
    display: flex;
    flex-direction: column;
  `}
`;
