import styled from 'styled-components';

export const Container = styled.div`
  display: block;
  cursor: grab;
  position: relative;

  img {
    max-height: 90vh;
    flex-grow: 1;
    width: 300px;
  }

  .keen-slider {
    margin: 0 5vw;
    overflow: visible;
  }

  .gatsby-image-wrapper {
    width: 300px;
  }

  .arrow {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    fill: #fff;
    cursor: pointer;
  }

  .arrow--left {
    left: 5px;
  }

  .arrow--right {
    left: auto;
    right: 5px;
  }

  .arrow--disabled {
    fill: rgba(255, 255, 255, 0.5);
  }
`;
